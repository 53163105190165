body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.AppBackground {
  background-color: #053237;
  height: 100vh;
  width: 100vw;
}

ul, li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.AppContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
}

.SocialNetworkButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border-style: solid solid solid none;
  border-color: #DAC574;
  border-width: 1px 1px 1px 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 300px;
  height: 50px;
}

.SocialNetworkTitle {
  color: #DAC574;
  font-size: 14px;
  width: 100%;
  text-align: center;
  letter-spacing: 0.2em;
}

.SocialNetworkIcon {
  position: relative;
}

.FacebookIcon {
  position: absolute;
  top: -27px;
  left: -20px;
  background-color: #053237;
  border-radius: 150px;
}

.WhatsAppIcon {
  position: absolute;
  top: -32px;
  left: -22px;
}

.InstagramIcon {
  position: absolute;
  top: -32px;
  left: -22px;
}

.SocialNetworkList {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.AppFooter {
  color: #FFF;
  background-color: #053237;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-bottom: 10px;
  font-size: 10px;
}


@media (max-width: 768px) {
  .SocialNetworkButton {
    margin-top: 20px;
    width: 150px;
    height: 40px;
  }

  .AppFooter {
    font-size: 10px;
  }

  .SocialNetworkTitle { 
    font-size: 10px;
  }

  .FacebookIcon {
    top: -25px;
    left: -20px;
  }

  .WhatsAppIcon {
    top: -25px;
    left: -22px;
  }

  .InstagramIcon {
    top: -25px;
    left: -22px;
  }

  .SocialNetworkList {
    gap: 10px;
  }
}